import Button from '$components/Button'
import Input from '$components/Input'
import Layout from '$components/Layout'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import * as React from 'react'
import { Helmet } from 'react-helmet'

function Contact() {
  return (
    <Layout>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <div
        css={{
          maxWidth: 600,
          margin: `${rhythm(1)} auto 0 auto`,
          padding: '0 20px',
          overflow: 'hidden',
        }}
      >
        <h2>Contact Us</h2>
        <p>
          Reach out and contact us anytime! We are also on most social media
          channels via @scope.travel
        </p>
        <ContactForm />
      </div>
    </Layout>
  )
}

function ContactForm() {
  return (
    <form
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      method="post"
    >
      <input type="hidden" name="form-name" value="contact" />

      <Input
        required
        name="email"
        type="email"
        placeholder="Email address"
        css={css`
          margin-bottom: ${rhythm(0.5)};
        `}
      />

      <Input
        required
        name="message"
        type="message"
        placeholder="Your message"
        css={css`
          margin-bottom: ${rhythm(0.5)};
        `}
      />

      <Button type="submit">Submit</Button>
    </form>
  )
}

export default Contact
